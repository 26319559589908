import { useDropzone } from "react-dropzone";
import './dropZoneUploader.scss'
import {useState} from "react";

const DropZoneUploader = ({
	preview,
	acceptPreviewHandler,
	className,
	text = 'Прикрепите файл',
}) => {
	const [uploadError, setUploadError] = useState(false);
	const { getRootProps, getInputProps } = useDropzone({
		onDrop: (f) => {
			console.log(f[0]);
			if (f[0].size / 1000 > 10240) {
				setUploadError(true);
			} else {
				acceptPreviewHandler(f);
			}
		},
		accept: {
			'image/jpeg': [],
			'image/png': []
		},
		maxFiles: 1,
	});

	return (
		<section className={`${uploadError ? 'DropZoneRootError' : 'DropZoneRoot'} ${className}`}  {...getRootProps()}>
				<div className="dropzone">
					{preview ? (
						<>
							<img
								src={typeof preview === "string" ? preview : URL.createObjectURL(preview.file)}
								alt=''
							/>
							{uploadError && (<div>Размер изображения не должен превышать 10 мб</div>)}
						</>
					) : (
						<div className="dropzone">
							<b>{uploadError ? 'Размер изображения не должен превышать 10 мб' : text}</b>
						</div>
					)}
					<input {...getInputProps()} />
				</div>
		</section>
	)
};

export default DropZoneUploader;